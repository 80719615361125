import React, { Component } from "react";
import Layout from '../components/layout';
import SEO from '../components/seo';
import SkillsCard from '../components/skillsCard';
import CodeIcon from '../vendors/fontAwesome/svgs/code.svg';
import DesktopIcon from '../vendors/fontAwesome/svgs/desktop.svg';
import CommentsIcon from '../vendors/fontAwesome/svgs/suitcase.svg';
import Avatar from '../images/about.svg';


// constants ==============
const devSkills = ["JavaScript","Node.js", "React","HTML","CSS","Sass","Webpack","Express","Git/GitHub","MongoDB","RESTful APIs", "CRUD"];
const adobeSkills = ["Photoshop","InDesign","XD (prototyping)"];
const businessSkills = ["Digital Ad Tech", "Client Relations", "Data Analysis", "Project Management", "Chinese, Japanese"];

// About Component ==============
class About extends Component {
	renderLi = (arr) => arr.map(el => {
		return (
			<li key={el}>{el}</li>
		);
	});

	render() {
		return (
			<Layout>
			<SEO title="About" />
			<div>
				<h1>About</h1>
				<center><img className="image" src={Avatar} alt="about" />
				</center>
				<p>
					I'm a web developer with a digital ad tech background. I am proficient with <strong>MERN</strong> (MongoDB, Express, React.js, Node.js) development stack.
				</p>
				<p>
					I have previously worked at companies such as <em>Martha Stewart, Meredith, Evening Standard,</em> and <em>The Independent</em>. In my previous roles, I have worked alongside company teams (both national and global) to help provide great ad experiences to clients and partners.
				</p>
				<p>Aside from coding, I'm a BTS fan and also play video games (mainly PS4 and Switch) on my downtime.</p>
				<br />
				<hr />

				<h2 className="text--center">Skills</h2>
				<div className="skills-container">
					<SkillsCard 
						icon={<CodeIcon className="icon--about" />}
						title="Development"
						children={
							<ul>
								{this.renderLi(devSkills)}
							</ul>
						}
					/>
					
					<SkillsCard 
						icon={<DesktopIcon className="icon--about" />}
						title="Software"
						children={
							<ul>
							<li><em>Adobe Suite</em></li>
							<ul>
								{this.renderLi(adobeSkills)}
							</ul>
							<br />
							<li>JIRA / Trello / Kanban</li>
						</ul>
						}
					/>

					<SkillsCard 
						icon={<CommentsIcon className="icon--about" />}
						title="Business"
						children={
							<ul>
								{this.renderLi(businessSkills)}
							</ul>
						}
					/>
				</div>
			</div>
		</Layout>
		);
	}
};

export default About;