import React from 'react';

const SkillsCard = ({ icon, title, children }) => (
  <div className="skills">
    <div className="skills-card-header">
      <div>{ icon }</div>
      <div><h1 className="skills-card-header-h1">{ title }</h1></div>
    </div>
    <div>{ children }</div>
  </div>
);

export default SkillsCard;